import { useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";


// books icon
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
// categories icon
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
// plus uicon
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

// logout
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";



const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    </Link>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");





  
const menuItems = [
    { header: "الفئات" },
    { title: "الفئات", to: "/admin/categories", icon: <CategoryOutlinedIcon />, roles: [] },
    { title: "إضافة فئة", to: "/admin/categories/add", icon: <AddCircleOutlineOutlinedIcon />, roles: [] },
    { header: "الكتب" },
    { title: "الكتب", to: "/admin/books", icon: <LocalLibraryOutlinedIcon />, roles: [] },
    { title: "إضافة كتاب", to: "/admin/books/add", icon: <AddCircleOutlineOutlinedIcon />, roles: [] },
    { title: "المستعيرين", to: "/admin/borrowers", icon: <PersonOutlinedIcon />, roles: [] },
    { header: "المشرفين" },
    { title: "المشرفين", to: "/admin/admins", icon: <PersonOutlinedIcon />, roles: [] },
    { title: "إضافة مشرف", to: "/admin/admins/add", icon: <AddCircleOutlineOutlinedIcon />, roles: [] },
    { header: "تسجيل الخروج" },
    { title: "تسجيل الخروج", to: "/admin/logout", icon: <ExitToAppOutlinedIcon />, roles: [] },
];

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
   
      <ProSidebar collapsed={isCollapsed}>
        
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img 
                  alt="logo"
                  style = {{
                    width: '50px',
                    height: '50px',
                    objectFit: 'contain',
                    marginRight: '10px'
                  }}
                  src="/logo.png"
                />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">

              <Box textAlign="center">


              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {menuItems.map((item, index) => (
              item.header ? (
                <Typography
                  key={index}
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  {item.header}
                </Typography>
              ) : (
                  <Item
                    key={index}
                    title={item.title}
                    to={item.to}
                    icon={item.icon}
                    selected={selected}
                    setSelected={setSelected}
                  />
                
              )
            ))}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
