import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

const AdminsList = () => {
    const [admins, setAdmins] = useState([]);
    const [error, setError] = useState(null);
    const authToken = localStorage.getItem('authToken');

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const response = await axios.get('https://api.mosque.sites.hostilna.com/admins', {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
                setAdmins(response.data.admins || []);
            } catch (error) {
                setError('خطأ في استرداد المسؤولين');
            }
        };

        fetchAdmins();
    }, [authToken]);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://api.mosque.sites.hostilna.com/admins/${id}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            setAdmins(admins.filter(admin => admin.id !== id));
        } catch (error) {
            setError('خطأ في حذف المسؤول');
        }
    };

    return (
        <Container>
            <Typography variant="h4">قائمة المسؤولين</Typography>
            {error && <Typography color="error">{error}</Typography>}
            <Button component={Link} to="/admin/admins/add" variant="contained" color="primary" style={{ marginBottom: '20px' }}>
                إضافة مسؤول
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>الاسم</TableCell>
                            <TableCell>البريد الإلكتروني</TableCell>
                            <TableCell>الإجراءات</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {admins.map((admin) => (
                            <TableRow key={admin.id}>
                                <TableCell>{admin.name}</TableCell>
                                <TableCell>{admin.email}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleDelete(admin.id)}
                                    >
                                        حذف
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default AdminsList;
