import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, TextField, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Typography, Button } from '@mui/material';

const BorrowersList = () => {
    const [borrowers, setBorrowers] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalBorrowers, setTotalBorrowers] = useState(0);

    const authToken = localStorage.getItem('authToken');

    const fetchBorrowers = async (page, rowsPerPage, search) => {
        try {
            const response = await axios.get('https://api.mosque.sites.hostilna.com/books/borrowers', {
                params: {
                    page: page + 1,  // Adjusting to match backend pagination (starting from 1)
                    limit: rowsPerPage,
                    search
                },
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            setBorrowers(response.data.borrowers);
            setTotalPages(response.data.pagination.totalPages);
            setTotalBorrowers(response.data.pagination.totalBorrowers);
        } catch (error) {
            console.error('Error fetching borrowers', error);
        }
    };

    useEffect(() => {
        fetchBorrowers(page, rowsPerPage, search);
    }, [page, rowsPerPage, search]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);  // Reset to first page when rows per page changes
    };

    const handleReturnBook = async (borrowerId, bookId) => {
        try {
            await axios.delete(`https://api.mosque.sites.hostilna.com/books/return/${borrowerId}/${bookId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            // Refresh the borrowers list after a book is returned
            fetchBorrowers(page, rowsPerPage, search);
        } catch (error) {
            console.error('Error returning the book', error);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>قائمة المستعيرين</Typography>

            <TextField
                label="البحث بالاسم، اللقب أو الهاتف"
                value={search}
                onChange={handleSearchChange}
                fullWidth
                margin="normal"
            />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>الاسم</TableCell>
                        <TableCell>اللقب</TableCell>
                        <TableCell>الهاتف</TableCell>
                        <TableCell>الكتاب</TableCell>
                        <TableCell>تاريخ الاستعارة</TableCell>
                        <TableCell>تاريخ الإرجاع</TableCell>
                        <TableCell>إرجاع الكتاب</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {borrowers.map((borrower) => (
                        <TableRow key={borrower.id}>
                            <TableCell>{borrower.name}</TableCell>
                            <TableCell>{borrower.fname}</TableCell>
                            <TableCell>{borrower.phone}</TableCell>
                            <TableCell>{borrower.book_title}</TableCell>
                            <TableCell>{new Date(borrower.date_of_borrow).toLocaleDateString()}</TableCell>
                            <TableCell>{new Date(borrower.date_of_return).toLocaleDateString()}</TableCell>
                            <TableCell>
                                <Button 
                                    variant="contained" 
                                    color="secondary"
                                    onClick={() => handleReturnBook(borrower.id, borrower.book_id)}
                                >
                                    إرجاع
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={totalBorrowers}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="صفوف في الصفحة"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} من ${count}`}
            />
        </Container>
    );
};

export default BorrowersList;
