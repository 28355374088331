import React from 'react';
import { useGlobalContext } from '../../context.';
import Book from "../BookList/Book";
import Loading from "../Loader/Loader";
import coverImg from "../../images/cover_not_found.jpg";
import "./BookList.css";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BookList = () => {
    const { books, loading, resultTitle, currentPage, setCurrentPage, totalPages } = useGlobalContext();
    
    const booksWithCovers = books.map((singleBook) => {
        return {
            ...singleBook,
            cover_img: singleBook.picture ? `https://api.mosque.sites.hostilna.com/public/books/${singleBook.picture}` : coverImg
        };
    });

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    };

    if (loading) return (
        <section className='booklist'>
            <div className='container'>
                <div className='section-title'>
                    <h2><Skeleton width={200} /></h2>
                </div>
                <div className='booklist-content grid'>
                    {
                        Array.from({ length: 30 }).map((item, index) => (
                            <div key={index} className='book-item flex flex-column flex-sb'>
                                <div className='book-item-img2'>
                                    <Skeleton width={200} height={300} />
                                </div>
                                <div className='book-item-info text-center'>
                                    <div className='book-item-info-item title fw-7 fs-18'>
                                        <Skeleton width={200} />
                                    </div>
                                    <div className='book-item-info-item author fs-15'>
                                        <Skeleton width={200} />
                                    </div>
                                    <div className='book-item-info-item edition-count fs-15'>
                                        <Skeleton width={200} />
                                    </div>
                                    <div className='book-item-info-item publish-year fs-15'>
                                        <Skeleton width={200} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );

    return (
        <section className='booklist'>
            <div className='container'>
                <img 
                    style={{ position: 'absolute', top: 0, left: 0, bottom: 0, width: '100%', height: '120vh', objectFit: 'cover', opacity: '0.2', zIndex: '-1' }}
                    src="/pattern.png" alt="" className="pattern" />
                <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, width: '100%', height: '120vh', background: "linear-gradient(#f8f9fa, transparent)", zIndex: '-1' }}></div>

                <div className='section-title'>
                    <h2>{resultTitle}</h2>
                </div>
                <div className='booklist-content grid'>
                    {booksWithCovers.map((item, index) => (
                        <Book key={index} {...item} />
                    ))}
                </div>
                {totalPages > 1 &&
                    <div className='pagination'>
                        <button
                            className='pagination-btn'
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            &lt;
                        </button>
                        <span className='pagination-info'>
                            Page {currentPage} sur {totalPages}
                        </span>
                        <button
                            className='pagination-btn'
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            &gt;
                        </button>
                    </div>
                }
            </div>
        </section>
    );
};

export default BookList;
