import React from 'react';
import { Link } from 'react-router-dom';
import "./BookList.css";

const Book = (book) => {
  return (
    <div className='book-item flex flex-column flex-sb'>
      <div className='book-item-img'>
        <img 
        style = { book.quantity < 1 ? {opacity: '0.5'} : {opacity: '1'}}
        src = {book.cover_img} alt = "cover" />
        
        {book.quantity < 1 &&
         <p
         style = {{
          textAlign: 'center', width: '100%', background: 'rgba(255, 255, 255, 0.8)',
          color: 'red', padding: '10px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '20px'}}
         >
          غير متوفر
         </p>
        }
      </div>
      <div className='book-item-info text-center'>
        <Link to = {`/book/${book.id}`} {...book}>
          <div className='book-item-info-item title fw-7 fs-18'>
            <span>{book.title}</span>
          </div>
        </Link>

        <div className='book-item-info-item author fs-15'>
          <span className='text-capitalize fw-7'>{book.author}

          </span>
          <span>

          </span>
        </div>

        <div className='book-item-info-item edition-count fs-15'>
          <span className='text-capitalize fw-7'>{book.publishing_house} </span>
          <span>{book.edition_count}</span>
        </div>

        <div className='book-item-info-item publish-year fs-15'>
          <span className='text-capitalize fw-7'>سنة 
             {book.year}
          </span>
          <span>{book.first_publish_year}</span>
        </div>
      </div>
    </div>
  )
}

export default Book