// src/pages/Admin/EditCategory.js
import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Container, Paper } from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [category, setCategory] = useState({ name: '', description: '' });
    const [error, setError] = useState('');


    useEffect(() => {
        const fetchCategory = async () => {
            const authToken = localStorage.getItem('authToken');
            try {
                const response = await axios.get(`https://api.mosque.sites.hostilna.com/categories/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
                setCategory(response.data);
            } catch (error) {
                setError('حدث خطأ أثناء استرداد الفئة');
            }
        };

        fetchCategory();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategory((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.put(`https://api.mosque.sites.hostilna.com/categories/${id}`, category);
            navigate('/admin/categories');
        } catch (error) {
            setError('حدث خطأ أثناء تحديث الفئة');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} style={{ padding: 20 }}>
                <Typography variant="h5" gutterBottom>
                    تعديل الفئة
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="الاسم"
                        name="name"
                        value={category.name}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="الوصف"
                        name="description"
                        value={category.description}
                        onChange={handleChange}
                    />
                    {error && (
                        <Typography color="error" variant="body2">
                            {error}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 20 }}
                    >
                        حفظ التعديلات
                    </Button>
                </form>
            </Paper>
        </Container>
    );
};

export default EditCategory;
