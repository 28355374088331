// src/components/Layout/Layout.js
import React, { useState } from 'react';
import Sidebar from '../Admin/Sidebar';
import { Outlet } from 'react-router-dom';
import './Layout.css'; // Importez votre fichier CSS pour la mise en page


const Layout = () => {
  const [toggle, setToggle] = useState(true);
  return (
    <div className="layout">
      <div 
      
      style={{transform: toggle ? 'translateX(0)' : 'translateX(100%)'}}
      className="sidebar">
      <div 
      onClick={() => setToggle(!toggle)}
      className="sidebar-toggle">
          &gt;
          </div>
      <Sidebar />

      </div>
      <div className="content">
        <Outlet /> {/* Ceci affichera le contenu des routes enfants */}
      </div>
    </div>
  );
};

export default Layout;
