// src/pages/Admin/Books.js
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Button } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Books = () => {
    const [books, setBooks] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                setLoading(true);
                const response = await axios.get('https://api.mosque.sites.hostilna.com/books', {
                    params: {
                        page: page + 1,
                        limit: rowsPerPage
                    }
                });
                setBooks(response.data.books || []);
                setTotal(response.data.totalBooks || 0);
            } catch (error) {
                setError('حدث خطأ أثناء استرداد الكتب');
            } finally {
                setLoading(false);
            }
        };

        fetchBooks();
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://api.mosque.sites.hostilna.com/books/${id}`);
            setBooks(books.filter(book => book.id !== id));
        } catch (error) {
            setError('حدث خطأ أثناء حذف الكتاب');
        }
    };

    if (loading) {
        return <Typography variant="h6">جارٍ التحميل...</Typography>;
    }

    if (error) {
        return <Typography variant="h6" color="error">{error}</Typography>;
    }

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                قائمة الكتب
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>العنوان</TableCell>
                            <TableCell>المؤلف</TableCell>
                            <TableCell>الناشر</TableCell>
                            <TableCell>السنة</TableCell>
                            <TableCell>رقم ISBN</TableCell>
                            <TableCell>الكمية</TableCell>
                            <TableCell>الإجراءات</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {books.length > 0 ? (
                            books.map((book) => (
                                <TableRow key={book.id}>
                                    <TableCell>{book.title}</TableCell>
                                    <TableCell>{book.author}</TableCell>
                                    <TableCell>{book.publishing_house}</TableCell>
                                    <TableCell>{book.year}</TableCell>
                                    <TableCell>{book.isbn}</TableCell>
                                    <TableCell>{book.quantity}</TableCell>
                                    <TableCell>
                                        <Button
                                            component={Link}
                                            to={`/admin/books/edit/${book.id}`}
                                            variant="contained"
                                            color="primary"
                                        >
                                            تعديل
                                        </Button>
                                        <Button
                                            onClick={() => handleDelete(book.id)}
                                            variant="contained"
                                            color="error"
                                            style={{ marginLeft: 8 }}
                                        >
                                            حذف
                                        </Button>
                                        <Button
                                            component={Link}
                                            to={`/admin/borrow/${book.id}`}
                                            variant="contained"
                                            color="secondary"                                >
                                              اقتراض
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6}>لم يتم العثور على أي كتاب</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
};

export default Books;
