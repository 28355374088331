import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const BorrowBook = () => {
    const { book_id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        fname: '',
        phone: '',
        date_of_borrow: '',
        date_of_return: ''
    });
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const authToken = localStorage.getItem('authToken');

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleBorrow = async () => {
        try {
            const response = await axios.post(`https://api.mosque.sites.hostilna.com/books/borrow/${book_id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            setSuccessMessage('Livre emprunté avec succès');
            setError(null);
            navigate('/admin/books');

        } catch (error) {
            setError('Erreur lors de l\'emprunt du livre');
            setSuccessMessage('');
        }
    };

    return (
        <Container>
            <Typography variant="h4">Emprunter un Livre</Typography>
            {error && <Typography color="error">{error}</Typography>}
            {successMessage && <Typography color="primary">{successMessage}</Typography>}
            <TextField
                label="Nom"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Prénom"
                name="fname"
                value={formData.fname}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Téléphone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Date d'emprunt"
                name="date_of_borrow"
                value={formData.date_of_borrow}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                label="Date de retour"
                name="date_of_return"
                value={formData.date_of_return}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Button variant="contained" color="primary" onClick={handleBorrow}>
                Emprunter
            </Button>
        </Container>
    );
};

export default BorrowBook;
